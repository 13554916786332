document.addEventListener('alpine:init', () => {
    Alpine.data('rightAccordion', () => ({
        buttons: [],
        init: function () {
            this.buttons = this.$el.querySelectorAll('a[data-type="show_less_button"]')
        },
        closePanels: function () {
            this.buttons.forEach((button) => {
                if (button.dataset.open === 'true') {
                    button.click()
                }
            })
        }
    }))
    Alpine.data('rightAccordionButton', () => ({
        height: 0,
        showContent: false,
        init: function () {
            if (this.height === 0) {
                this.$refs.content.style.maxHeight = '0px'
                this.$refs.button.dataset.open = false

                this.height = this.$refs.content.clientHeight
            }
        },

        clickButton: function () {
            const direction = !this.showContent
            setTimeout(() => {
                this.$nextTick(() => {
                    this.showContent = direction

                    this.$refs.content.style.transition = direction ? 'max-height 0.75s ease-in' : 'max-height 0.5s ease-out'
                    this.$refs.content.style.maxHeight = direction ? '1000px' : '0px'
                    this.$refs.button.dataset.open = direction
                });
            }, 1)

        }
    }))
})